const clickOutside = {
    beforeMount: (el, binding) => {
        binding.event = (event) => {
            // here I check that click was outside the el and his children
            if (!(el === event.target || el.contains(event.target))) {
                // and if it did, call method provided in attribute value
                if (binding.value instanceof Function) {
                    binding.value(true);
                }
            } else {
                if (binding.value instanceof Function) {
                    binding.value(false);
                }
            }
        };
        document.body.addEventListener('click', binding.event);
    },

    mounted: (el, binding) => {
        const dialogs = document.querySelectorAll('[role="dialog"]')
        for (const element of dialogs) {
            element.addEventListener('mousedown', binding.event);
        }
    },

    beforeUnmount(el, binding) {
        const dialogs = document.querySelectorAll('[role="dialog"]')
        for (const element of dialogs) {
            element.addEventListener('mousedown', binding.event);
        }
    },

    unmounted: (el, binding) => {
        document.body.removeEventListener('click', binding.event);
    }
};

export default clickOutside;
